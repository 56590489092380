/**********************************************************************************
 * COMPONENTS
 * Name : Footer
 **********************************************************************************/

/**
 * Styles
 **********************************************************************************/

.c-footer {}

  .c-footer__wrapper {
    @extend .o-wrapper;
    @extend .o-wrapper--huge;
    padding: rem(80px) 0;
    text-align: center;
  }

  .c-footer__head {
    margin-bottom: rem(80px);
  }

    .c-footer__svg {
      width: rem(278px);
    }

  .c-footer__body {
    margin: 0 auto rem(80px);
    max-width: rem(482px);
    line-height: 1.2;
  }

    .c-footer__title {
      margin-bottom: rem($global-gutter * 1.5);
    }

  .c-footer__foot {
    font-size: rem(14px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: rem($global-gutter);
    padding-top: rem($global-gutter);
    border-top: 1px solid tint($color-black, 80);
  }

    .c-footer__legal {
      text-decoration: none;

      &:hover{
       text-decoration: underline;
      }
    }

/**
 * Media Queries
 **********************************************************************************/

@include mq($until: regular) {
  .c-footer__svg {
    width: rem(186px);
  }

  .c-footer__foot {
    justify-content: center;
  }
}
