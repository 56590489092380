/**********************************************************************************
 * GENERIC
 * Name : Fonts
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

 @include font-face(
   "Satoshi",
   "Satoshi-Bold",
   $font-bold,
   normal,
   $suffix: ""
 );

@include font-face(
  "Satoshi",
  "Satoshi-Regular",
  $font-regular,
  normal,
  $suffix: ""
);
