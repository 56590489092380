/**********************************************************************************
 * COMPONENTS
 * Name : Figure
 **********************************************************************************/

/**
 * Styles
 **********************************************************************************/

.c-hero {
  position: relative;
  z-index: 0;
}

  .c-hero__figure {
    position: relative;
    height: calc(100vh - 50px);

    &:before {
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(15deg, rgba(0,0,0,0.5007396708683473) 40%, rgba(255,255,255,0) 100%);
    }
  }

  .c-hero__bottom {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: end;
    color: $color-white;
    @include fluid-size($global-gutter, 80px, bottom);
    width: 100%;
    padding: 0 rem(80px);
    @include fluid-size($global-gutter, 80px, padding-left);
    @include fluid-size($global-gutter, 80px, padding-right);
  }

    .c-hero__left {
      display: flex;
      flex-direction: column;
      max-width: rem(695px);

      & > * { margin: 0; }

      & > * + * { margin-top: rem($global-gutter); }
    }

      .c-hero__title {
        line-height: 1;
      }

      .c-hero__subtitle {
        font-size: rem(20px);
      }

    .c-hero__right {}

      .c-hero__discover {
        display: block;
        text-align: center;
        text-decoration: none;
      }

        .c-hero__arrow {
          display: flex;
          justify-content: center;
          align-content: center;
          padding: rem(32px);
          border: 1px solid $color-white;
          border-radius: 100px;
        }

          .c-hero__svg {
            transform: translateY(0);
            @include transition(transform opacity);
          }

        .c-hero__downlabel {
          display: block;
          margin-top: rem(8px);
        }

/**
* States
**********************************************************************************/

@keyframes discover {
  0% {
    transform: translateY(-70%);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(70%);
    opacity: 0;
  }
}

.c-hero__discover {
  &:hover {
    .c-hero__svg {
      animation: discover 1.5s linear infinite;
    }
  }
}

/**
 * Media Queries
 **********************************************************************************/

@include mq($until: regular) {
    .c-hero__figure{
      height: rem(620px);
    }

    .c-hero__right {
      display: none;
    }
}

@include mq($until: small) {
  .c-hero__figure{
    height: rem(475px);
  }
}


