/**********************************************************************************
 * COMPONENTS
 * Name : Structure
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

.c-structures {
  max-width: 100%;
  margin-top: rem($global-gutter * 4);
  @include fluid-size(32px, 40px, margin-bottom);
}

/**
 * Modifiers
 **********************************************************************************/

.c-structures--overflow {
  &.swiper{
    overflow: initial;
  }
}
