/**********************************************************************************
 * ELEMENTS
 * Name : Headings
 *
 * Description :
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 *
 **********************************************************************************/

$headings-font-size-h1:  $global-font-size * 5;
$headings-font-size-h2:  $global-font-size * 2.625;
$headings-font-size-h3:  $global-font-size * 2;
$headings-font-size-h4:  $global-font-size * 2;
$headings-font-size-h5:  $global-font-size * 1.5;
$headings-font-size-h6:  $global-font-size;

h1 {
  @include fluid-size(30px, $headings-font-size-h1);
}

h2 {
  @include fluid-size(30px, $headings-font-size-h2);
}

h3 {
  font-size: rem($headings-font-size-h3);
}

h4 {
  font-size: rem($headings-font-size-h4);
}

h5 {
  font-size: rem($headings-font-size-h5);
}

h6 {
  font-size: rem($headings-font-size-h6);
}
