/**********************************************************************************
 * ELEMENTS
 * Name : Form
 **********************************************************************************/

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  float: left;
}
