/**********************************************************************************
 * OBJECTS
 * Name : Media
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$media-gutter: $global-gutter * 1.5;
$media-gutter-large: $global-gutter * 5;


/**
 * Styles
 **********************************************************************************/

.o-media {

}

.o-media__figure {
  margin-bottom: rem($media-gutter);
  text-align: center;
}

.o-media__richtext {

}


/**
 * Media Queries
 **********************************************************************************/

//@include mq($from: regular) {
//  .o-media__figure {
//    max-width: 50%;
//    @include fluid-size($media-gutter, $media-gutter-large, margin-right);
//    float: left;
//  }
//}


/**
 * Modifiers
 **********************************************************************************/

// Right

.o-media--right {
  @include mq($from: large) {
    .o-media__figure {
      order: 1;
      margin-right: 0;
      @include fluid-size($media-gutter, $media-gutter-large, margin-left);
    }
  }
}

// Left

.o-media--left {
  @include mq($from: large) {
    .o-media__figure {
      order: 0;
      margin-right: 0;
      @include fluid-size($media-gutter, $media-gutter-large, margin-right);
    }
  }
}

// Columned

.o-media--columned {
  @include mq($from: large) {
    align-items: flex-start;
    display: flex;

    &.o-media--center{
      align-items: center;
    }

    .o-media__figure {
      flex: 0 0 auto;
      float: none;
    }

    .o-media__richtext {
      flex: 1 1 auto;
      float: none;
    }

    &.o-media--right {
      justify-content: flex-end;
    }

    &.o-media--left {
      justify-content: flex-start;
    }
  }
}

