/**********************************************************************************
 * COMPONENTS
 * Name : Header
 **********************************************************************************/

/**
 * Styles
 **********************************************************************************/

.c-header {
  position: absolute;
  z-index: 2;
  width: 100%;
  padding: rem(40px);
  text-align: center;
}

  .c-header__logo {}

    .c-header__svg {
      @include fluid-size(185px, 278px, width);
    }

/**
 * Styles
 **********************************************************************************/

.c-header--relative {
  position: relative;
}
