/**********************************************************************************
 * TOOLS
 * Name : Fonts
 **********************************************************************************/
@use "sass:math";

// Generate font-face
@mixin font-face($global-font-family, $file-name, $weight: normal, $style: normal, $id: $global-font-family, $suffix: "-webfont") {
  @font-face {
    font-display: swap;
    font-family: $global-font-family;
    font-style: $style;
    font-weight: $weight;
    src: url("#{$font-folder}#{$file-name}#{$suffix}.woff");
    src:
      url("#{$font-folder}#{$file-name}#{$suffix}.woff2")  format("woff2"),
      url("#{$font-folder}#{$file-name}#{$suffix}.woff")   format("woff");
  }
}

// Create rem base font-size with line-height
@mixin font-size($global-font-size: $global-font-size, $line-height: auto) {
  @include rem(font-size, $global-font-size);

  @if $line-height == auto {
    line-height: ceil($global-font-size / $global-line-height-px) * ($global-line-height-px / $global-font-size);
  }
  @else if type-of($line-height) == number or $line-height == inherit or $line-height == normal {
    @if type-of($line-height) == number and unit($line-height) == px {
      line-height: $line-height / $global-font-size;
    }
    @else {
      line-height: $line-height;
    }
  }
}

// Create responsive font-size
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size, $relative-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);
  $u5: unit($relative-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media (min-width: $min-vw) {
        font-size: calc(#{ em($min-font-size, $relative-font-size) } + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$fluid-size-fallback: false !default;

/* stylelint-disable max-line-length */
@function fluid-size-value($size-min, $size-max, $vw-min: 400px, $vw-max: 960px) {
  $slope: math.div($size-max - $size-min, $vw-max - $vw-min);
  $slope-to-unit: $slope * 100 * 1vw;
  $intercept-rem: rem($size-min - $slope * $vw-min);
  $size-min-rem: rem($size-min);
  $size-max-rem: rem($size-max);

  @return clamp(#{$size-min-rem}, #{$slope-to-unit} + #{$intercept-rem}, #{$size-max-rem});
}

@mixin fluid-size($size-min: 16px, $size-max: 18px, $property: font-size, $vw-min: 400px, $vw-max: 960px) {
  $u1: math.unit($size-min);
  $u2: math.unit($size-max);
  $u3: math.unit($vw-min);
  $u4: math.unit($vw-max);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    $value: fluid-size-value($size-min, $size-max, $vw-min, $vw-max);

    @if $fluid-size-fallback {
      #{$property}: rem($size-min);

      @supports (#{$property}: clamp(#{ rem($size-min) }, #{ rem($size-limit) } + #{ $vw-const }, #{ rem($size-max) })) {
        #{$property}: #{ $value };
      }
    } @else {
      #{$property}: #{ $value };
    }
  } @else {
    @error "All units must be the same.";
  }
}
/* stylelint-enable max-line-length */
