/**********************************************************************************
 * COMPONENTS
 * Name : Controls
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

.c-controls {}

  .c-controls__btn {
    padding: 0;
    background: transparent;
    border:0;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: 0.4;
    }
  }

/**
 * Modifier
 **********************************************************************************/

.c-controls--arrows {

  .c-controls__btn {

    &:first-child {
      margin-right: rem($global-gutter * 2);
    }

    &:last-child {
      margin-left: rem($global-gutter * 2);
    }
  }
}
