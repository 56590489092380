/**********************************************************************************
 * OBJECTS
 * Name : Wrapper
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$wrapper-gutter: $global-gutter;
$wrapper-column: 60px;
$wrapper-width: $wrapper-column * 12 + $wrapper-gutter * 11; // 940

$wrappers-sizes: (
  "mini": $wrapper-column * 3 + $wrapper-gutter * 2, // 220
  "tiny": $wrapper-column * 4 + $wrapper-gutter * 3, // 300
  "small": $wrapper-column * 6 + $wrapper-gutter * 5, // 460
  "regular": $wrapper-column * 8 + $wrapper-gutter * 7, // 620
  "medium": $wrapper-column * 10 + $wrapper-gutter * 9, // 780
  "large": $wrapper-column * 14 + $wrapper-gutter * 14, // 1100
  "huge": $wrapper-column * 16 + $wrapper-gutter * 15, // 1200
  "giant": $wrapper-column * 18 + $wrapper-gutter * 17 // 1420
);


/**
 * Styles
 **********************************************************************************/

.o-wrapper {
  position: relative;
  width: calc(100% - #{ rem($wrapper-gutter * 2) });
  max-width: rem($wrapper-width);
  box-sizing: content-box;
  margin-right: auto;
  margin-left: auto;
  padding-right: rem($wrapper-gutter);
  padding-left: rem($wrapper-gutter);

  & > * {
    box-sizing: border-box;
  }

  @include clearfix();

  @each $namespace, $size in $wrappers-sizes {
    &--#{ $namespace } {
      max-width: rem($size);
    }
  }
}


/**
 * Modifiers
 **********************************************************************************/

.o-wrapper--full {
  max-width: 100%;
}

// tiny
.o-wrapper--small {
  max-width: rem(690px);
}

// large
.o-wrapper--large {
  max-width: rem(998px);
}

// No horizontal paddings
.o-wrapper--wide {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

// Stetching children
.o-wrapper--stretch {
  height: 100%;

  & > * { height: 100%; }
}
