/**********************************************************************************
 * COMPONENTS
 * Name : numbers
 **********************************************************************************/

/**
 * Variable
 **********************************************************************************/

$numbers-font-size: $global-font-size;

/**
 * Styles
 **********************************************************************************/

.c-numbers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

  .c-numbers__item {
    font-size: rem($numbers-font-size);
    text-align: center;
    flex-basis: 25%;
    line-height: 1.5;
  }

    .c-numbers__head {
      display: inline-block;
      margin-bottom: rem($global-gutter * 1.5);
      width: rem(80px);
      height: rem(80px);
    }

    .c-numbers__body {
      font-size: rem(42px);
      font-weight: bold;
    }

    .c-numbers__foot {}

      .c-numbers__baseline {
        font-size: rem($numbers-font-size - 0.875);
        color: shade($color-white, 40);
      }

/**
 * Media Queries
 **********************************************************************************/

.c-numbers {
  @include mq($until: large) {
    row-gap: rem($global-gutter * 2.5);
    column-gap: rem($global-gutter);

    .c-numbers__item {
      flex-basis: calc(50% - rem($global-gutter));
    }

    .c-numbers__head {
      width: rem(64px);
      height: rem(64px);
    }

    .c-numbers__body {
      font-size: rem(26px);
    }
  }
}

