/**********************************************************************************
 * ELEMENTS
 * Name : Headings
 *
 * Description :
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 *
 **********************************************************************************/

html {
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  background: $color-background none;
  color: $color-foreground;
  font-family: $font-default;
  font-size: ($global-font-size / 16px) * 1em; /* [1] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: $global-line-height; /* [1] */
  scroll-behavior: smooth;
}

body {
  &.no-scroll {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
  }
}


/**
 * Print
 **********************************************************************************/

@media print {
  @page {
    margin: 1cm 1.5cm;
  }

  html,
  body {
    height: auto;
  }

  html {
    @include rem-baseline(90%);
  }

  *,
  *::before,
  *::after {
    border-color: #000 !important;
    background: transparent none !important;
    box-shadow: none !important;
    color: #000 !important;
  }
}
