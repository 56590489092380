/**********************************************************************************
 * SETTINGS
 * Name : Global
 *
 * The global settings file contains any project-wide variables; things that
 * need to be made available to the entire codebase.
 **********************************************************************************/


/**
 * High level
 **********************************************************************************/

$global-font-size: 16px;
$global-line-height-px: 28px;
$global-line-height: $global-line-height-px / $global-font-size;
$global-header-zindex: 20;
$global-footer-zindex: 10;


/**
 * UI
 **********************************************************************************/

$global-border-width: 2px;
$global-border-color: $color-black;
$global-gutter: 16px;
$global-icons: (
  //marker: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="%color%" d="M256 266.87c-37 0-65.22-28.26-65.22-65.22S219 136.43 256 136.43s65.22 28.27 65.22 65.22S293 266.87 256 266.87zM256 6C160.35 6 60.35 79.91 60.35 201.65c0 115.22 173.91 291.31 180.43 297.83 4.35 4.35 8.7 6.52 15.22 6.52s10.87-2.17 15.22-6.52c6.52-6.52 180.43-182.61 180.43-297.83C451.65 79.91 351.65 6 256 6z"/></svg>',
  marker: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 12" width="24" height="24"><path fill="%color%" d="M7.976 5.276a.6.6 0 0 1 .848 0l6.3 6.3a.6.6 0 0 1 0 .848l-6.3 6.3a.6.6 0 0 1-.848-.848L13.85 12 7.976 6.124a.6.6 0 0 1 0-.848Z"/></svg>',
);

