/**********************************************************************************
 * COMPONENTS
 * Name : Projects
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

.c-projects {
  position: relative;
  max-width: 100%;
  @include fluid-size(40px, 64px, margin-top);
  @include fluid-size(32px, 40px, margin-bottom);
}

  .c-projects__list {}

    .c-projects__item {
      @include fluid-size(24px, 40px, margin-bottom);
      max-width: rem(792px);

      &:not(.swiper-slide-active) {
        opacity: 0.4;
      }
    }

      .c-projects__figure {
        margin-right: auto;
        margin-left: auto;
        @include fluid-size(22px, 48px, margin-bottom);
      }

      .c-projects__description {}

        .c-projects__name {
          @include fluid-size(22px, 48px, font-size);
          @include fluid-size(30px, 48px, margin-bottom);
          display: inline;
          line-height: 1.5;
        }

        .c-projects__localisation {}

          .c-projects__city {
            vertical-align: text-bottom;
          }

  .c-projects__controls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 38%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    width: rem(920px);
  }


  .c-projects__counters {
    @extend .o-wrapper;
    position: relative;
    display: flex;
    justify-content: end;
    max-width: rem(792px);
  }

    .c-projects__pagination {
      position: absolute;
      transform: translateY(-50%);
      text-align: center;

      .swiper-pagination-bullet {
        vertical-align: middle;
        width: rem(4px);
        height: rem(4px);
        background-color: $color-dark-blue;
        opacity: 1;

        &-active {
          width: rem(8px);
          height: rem(8px);
          background-color: $color-gold;
        }
      }

    }

    .c-projects__fraction {
      display: flex;
      align-items: center;
      @include fluid-size(20px, 23px, font-size);
      font-weight: bold;
      line-height: 1;
    }

      .c-projects__current {
        @include fluid-size(30px, 80px, font-size);
      }


/**
 * Modifiers
 **********************************************************************************/

.c-projects--overflow {
  &.swiper{
    overflow: initial;
  }
}

/**
 * Media Queries
 **********************************************************************************/

@include mq($until: large) {
  .c-project__list {}

    .c-projects__item {
      max-width: rem(560px);
    }

  .c-projects__controls {
    position: relative;
    margin-top: rem($global-gutter * 1.5);
    top: initial;
    width: 100%;
  }

  .c-projects__counters {
    margin-top: rem(-50px);
    flex-direction: column;
    align-items: center;
  }

    .c-projects__pagination {
      position: relative;
      transform: translateY(0);
    }
}

@include mq($until: regular) {

  .c-projects__item {
    max-width: rem(303px);

  }

}
