/**********************************************************************************
 * SETTINGS
 * Name : Colors
 **********************************************************************************/


/**
 * Descriptives colors
 **********************************************************************************/

$color-black: #000;
$color-white: #fff;
$color-blue: #2e55ff;
$color-green: #27ae60;
$color-red: #c0392b;

$color-beige: #F5F4F0;
$color-dark-blue: #041A29;
$color-dark-green: #042919;
$color-gold: #937125;
$color-yellow: #DCB253;




/**
 * Functionals colors
 **********************************************************************************/

$color-background: $color-beige;
$color-foreground: $color-dark-blue;
$color-primary: $color-dark-green;
$color-secondary: $color-gold;
$color-green-section: $color-green;

// Feedback
$color-success: $color-green;
$color-error: $color-red;
$color-outline: $color-primary;
