/**********************************************************************************
 * COMPONENTS
 * Name : Button
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$button-font-size: $global-font-size;
$button-border-width: 1px;

$button-primary-color: $color-yellow;


/**
* Styles
**********************************************************************************/

.c-button {
  @include appearance;
  color: $color-dark-blue;
  align-items: center;
  position: relative;
  display: inline-flex;
  min-height: em($global-gutter * 3, $button-font-size);
  overflow: hidden;
  padding: em(9px, $button-font-size);
  border-radius: rem(6px);
  font-size: rem($button-font-size);
  line-height: $spacing-unit / $button-font-size;
  font-weight: $font-bold;
  text-align: left;
  text-decoration: none;
  background: $button-primary-color;

  &:before{
    content: '';
    position: absolute;
    width: rem(24px);
    height: rem(24px);
    top: 50%;
    left: 50%;
    border-radius: 100px;
    background: tint($button-primary-color, 20);
    transform: translate(-50%, -50%) scale(0);
    @include transition(transform);
  }

  &--disabled,
  &[aria-disabled="true"],
  &[disabled] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    &:before {
      transform: translate(-50%, -50%) scale(5);
    }
  }
}

.c-button__icon {
  flex: 0 0 auto;
  position: relative;
  display: inline-block;
  margin: 0 rem(5px);
  fill: currentColor;
  font-size: $spacing-unit / $button-font-size * 1em;
}

.c-button__label {
  flex: 1 1 auto;
  position: relative;
  display: inline-block;
  margin: rem(0px 8px);
}

/**
 * Print
 **********************************************************************************/

@media print {
  .c-button { break-inside: avoid; }

  .c-button--primary {
    background-color: #000 !important;
    color: #fff !important;

    .c-button__icon > *,
    .c-button__label,
    .c-button__sublabel {
      color: #fff !important;
    }
  }
}
