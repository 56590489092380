/**********************************************************************************
 * COMPONENTS
 * Name : Title
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

.c-title {
  margin-bottom: rem($global-gutter * 1.5);
}

  .c-title__surtitle {
    display: block;
    color: $color-gold;
    font-weight: 700;
    font-size: rem(16px);
    text-transform: uppercase;
    margin-bottom: rem(8px);
  }

  .c-title__h2 {
    margin: 0;
    line-height: 50px / $headings-font-size-h2;
  }
