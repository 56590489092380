/**********************************************************************************
 * RESPONSIVE SPACINGS - UTILITIES
 **********************************************************************************/

/**
 * Utility classes enhancing the normal spacing classes by adding responsiveness
 * to them. By default, there are not responsive spacings defined. You can
 * generate responsive spacings by adding entries to the following three Sass
 * maps, e.g.:
 *
 *   $responsive-spacing-directions: (
 *     null: null,
 *     "-bottom": "-bottom",
 *   );
 *
 *   $responsive-spacing-properties: (
 *     "margin": "margin",
 *   );
 *
 *   $responsive-spacing-sizes: (
 *     "-small": $global-spacing-unit-small,
 *   );
 *
 * This would bring us the following classes:
 *
 *   .u-margin-small@tiny {}
 *   .u-margin-small@small {}
 *   .u-margin-small@medium {}
 *   .u-margin-small@large {}
 *   .u-margin-bottom-small@tiny {}
 *   .u-margin-bottom-small@small {}
 *   .u-margin-bottom-small@medium {}
 *   .u-margin-bottom-small@large {}
 */


/**
 * Variables
 **********************************************************************************/

// The responsive spacings just make sense and work properly when the ‘normal’
// spacings are included, too. In case they're not, we set `_utilities.spacings`
// to `null`.

$responsive-breakpoint-separator: \@;

$responsive-spacing-directions: (
  // null: null,
   "-top": "-top",
   "-right": "-right",
  // "-bottom": "-bottom",
   "-left": "-left",
  // "-horizontal": "-left" "-right",
  // "-vertical": "-top" "-bottom",
);

$responsive-spacing-properties: (
   "padding": "padding",
   "margin": "margin",
);

$responsive-spacing-sizes: (
  // null: $spacing-unit,
  // "-tiny": $spacing-unit-tiny,
   "-small": $spacing-unit-small,
   //"-medium": $space-unit-medium,
   "-large": $spacing-unit-large,
   "-huge": $spacing-unit-huge,
   "-none": 0
);

// Use Sass-MQ breakpoints by default
$responsive-spacing-breakpoints: $mq-breakpoints;

/**
 * Styles
 **********************************************************************************/

// If the ‘normal’ spacings partial is not included, we provide an error message
// to indicate this.
@if $spacing-directions == null {
  @error "In order to use responsive spacings, you also need to include `_utilities.spacings.scss`!";
}
@else {
  // This defines the separator for the breakpoints suffix
  // in the class name. By default, we are generating the responsive suffixes
  // for the classes with a `@` symbol so you get classes like:
  //
  //   <div class="u-margin-bottom@tiny">
  //
  // Be aware that since the `@` symbol is a reserved symbol in CSS, it has to be
  // escaped with a `\`. In the markup though, you write your classes without the
  // backslash (e.g. `u-margin-bottom@tiny`).

  /* stylelint-disable max-nesting-depth */

  // Don't output anything if no responsive spacings are defined.
  @if ($responsive-spacing-properties != null) {
    @each $property-namespace, $property in $responsive-spacing-properties {
      @each $direction-namespace, $direction-rules in $responsive-spacing-directions {
        @each $size-namespace, $size in $responsive-spacing-sizes {
          @each $bp-name, $bp-value in $responsive-spacing-breakpoints {
            @include mq($from: $bp-name) {
              .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}#{$responsive-breakpoint-separator}#{$bp-name} {
                @each $direction in $direction-rules {
                  #{$property}#{$direction}: $size !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
