/**********************************************************************************
 * SCOPES
 * Name : Richtext
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$richtext-font-size: $global-font-size;
$richtext-line-height: $global-line-height;


/**
 * Styles
 **********************************************************************************/

.s-richtext {

  h1 {
    margin-top: 0;
  }

  h2, h3, h4, h5, h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: $font-extrabold;
    line-height: 1.2;
  }

  h1 + h2,
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6 {
    margin-top: 0.25em;
  }

  ul,
  ol {
    list-style: none;
    padding: 0;

    @include mq($until: regular) { margin-left: rem($global-gutter * 1.4); }

    @include mq($from: regular) { margin-left: rem($global-gutter * 1.4); }
  }

  li {
    padding-left: rem(4px);
    position: relative;
  }

  ul {
    li {
      list-style-image: icon(map-get($global-icons, 'marker'), $color-gold);
    }
  }
}

/**
 * Print
 **********************************************************************************/

@media print {
  .s-richtext {
    a[href]::after {
      content: " [" attr(href) "]";
      font-style: italic;
      opacity: 0.5;
    }

    abbr[title]::after {
      content: " [" attr(title) "]";
      font-style: italic;
      opacity: 0.5;
    }
  }
}
