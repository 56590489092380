/**********************************************************************************
 * TOOLS
 * Name: Animations
 **********************************************************************************/

/*
  Declare transitions more easily.
  Usage :
    @include transition(transform);
    @include transition(background color transform, 0.5s, ease-out, 0.25s);
*/
@mixin transition($properties, $duration: $animations-transition-duration, $easing: $animations-timing-function, $delay: 0s) {
  $r: "";
  @each $property in $properties {
    $r: $r + "#{$property} #{$duration} #{$easing} #{$delay}, ";
  }

  transition: str-slice(#{$r}, 0, -3);
}
