/**********************************************************************************
 * SETTINGS
 * Name : Fonts
 **********************************************************************************/

// Folder path
$font-folder: "../fonts/front/";

// Font families
$font-sans-serif: "Satoshi", sans-serif;
$font-default: $font-sans-serif;

// Font weights
$font-thin: 100;
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;
