/**********************************************************************************
 * TEXT - UTILITIES
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

.u-text-left {
  text-align: left !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-inherit {
  text-align: inherit !important;
}

.u-high-font {
  font-size: rem(18px);
}
