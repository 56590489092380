/**********************************************************************************
 * OBJECTS
 * Name : Grid
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$grid-gutter: $global-gutter;


/**
 * Styles
 **********************************************************************************/

.o-grid {
  --space: #{ rem($grid-gutter) };

  display: block;
  margin: 0;
  padding: 0;
}

  .o-grid__items {
    flex-wrap: wrap;
    display: flex;
    margin: calc(var(--space) / -2);
    padding: 0;
    list-style: none;
  }

    .o-grid__item {
      width: 100%;
      padding: calc(var(--space) / 2);
    }


/**
 * Modifiers
 **********************************************************************************/



.o-grid--stick {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(1px);
    height: 50%;
    background-color: $color-gold;
    transform: translate(-50%, -50%);
  }


  @include mq($until: large) {
    &:before {
      display: none;
    }
  }
}

.o-grid--Xcenter{

  .o-grid__items{
    align-items: center;
  }

}

.o-grid--Ycenter{

  .o-grid__items{
    justify-content: center;
  }

}
