/**********************************************************************************
 * COMPONENTS
 * Name : Figure
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$figure-font-size: $global-font-size;


/**
 * Styles
 **********************************************************************************/

.c-figure {
  display: block;
  max-width: 100%;
  margin: 0;
  font-size: rem($figure-font-size);
  line-height: 0;
}

.c-figure__image {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * Modifiers
 **********************************************************************************/

// Full
.c-figure--full {
  width: 100%;

  .c-figure__image {
    width: 100%;
  }
}

// Cover
.c-figure--cover {
  picture,
  .c-figure__image {
    width: 100%;
    height: 100%;
  }

  .c-figure__image {
    object-fit: cover;
  }
}

// Contain
.c-figure--contain {
  .c-figure__image {
    width: 100%;
    height: 100%;
  }

  .c-figure__image {
    object-fit: contain;
  }
}

/**
 * Print
 **********************************************************************************/

@media print {
  .c-figure { break-inside: avoid; }
}
