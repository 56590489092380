/**********************************************************************************
 * COMPONENTS
 * Name : Card
 **********************************************************************************/

/**
 * Variable
 **********************************************************************************/

$card-gutter: $global-gutter;
$card-font-size: 14px;

/**
 * Styles
 **********************************************************************************/

.c-card {
  font-size: $card-font-size;
  max-width: rem(287px);
  background-color: $color-white;
  box-shadow: 0px 24px 32px -16px rgba(0, 0, 0, 0.12);
}

  .c-card__wrapper {
    display: flex;
    flex-direction: column;
    min-height: rem(438px);
    padding: $card-gutter * 2;

    > * { margin: 0; }

    > * + * {
      margin-top: $card-gutter * 1.5;
    }
  }

    .c-card__head { flex: 0 0 auto }

    .c-card__body {
      flex-grow: 1;
      > * { margin: 0; }

      > * + * {
        margin-top: $card-gutter * 0.75;
      }
    }

      .c-card__title {
        color: $color-gold;
        line-height: 1.2;
      }

    .c-card__foot { flex: 0 0 auto }

      .c-card__link {
        font-size: $global-font-size;
        font-weight: 600;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

        .c-card__external {
          vertical-align: sub;
        }

/**
 * Modifiers
 **********************************************************************************/

.c-card__slide.swiper-slide {
  max-width: rem(287px);
}
