/**********************************************************************************
 * TOOLS
 * Name: Appearance
 * Description: Reset appearance of an element
 **********************************************************************************/

@mixin appearance {
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: inherit;
  text-align: inherit;
  appearance: none;
  vertical-align: top;
}
