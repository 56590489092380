/**********************************************************************************
 * COLORS - UTILITIES
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$colors-properties: (
  // "border": "border-color",
   "background": "background-color",
   "color": "color",
);

$colors-variants: (
  // "-black" $color-black,
   "-white" $color-white,
   "-green" $color-dark-green,
  // "-foreground" $color-foreground,
  // "-background" $color-background,
  // "-primary" $color-primary,
  // "-error" $color-error,
  // "-success" $color-success,
);


/**
 * Styles
 **********************************************************************************/

@each $selector, $property in $colors-properties {
  @each $color, $value in $colors-variants {
    .u-#{$selector}#{$color} {
      #{$property}: $value !important;
    }
  }
}


/**
 * Print
 **********************************************************************************/

@media print {
  [class*="u-background-"] {
    background-color: $color-white !important;
  }

  [class*="u-color-"] {
    color: $color-black !important;
  }
}
