/**********************************************************************************
 * GENERIC
 * Name : Box-sizing
 *
 * Description :
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 *
 ******************************************************************************/


/**
 * Styles
 **********************************************************************************/

html {
  box-sizing: border-box;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}
