/**********************************************************************************
 * OBJECTS
 * Name : Grid
 **********************************************************************************/


/**
 * Variables
 **********************************************************************************/

$grid-gutter: $global-gutter;


/**
 * Styles
 **********************************************************************************/

.o-main {
  display: block;
  margin: 0;
  padding: 0;
}

.o-main__items{}

.o-main__item {
  @include fluid-size(40px, 112px, padding-top);
  @include fluid-size(40px, 112px, padding-bottom);
}

/**
 * Modifiers
 **********************************************************************************/

.o-main__item--ohidden { overflow: hidden }
