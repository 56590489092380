/**********************************************************************************
 * ELEMENTS
 * Name : Typography
 **********************************************************************************/

a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

strong {
  font-weight: $font-bold;
}

hr {
  margin: 2em 0;
  border-top: 1px dashed currentColor;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  color: inherit;
}

/**
 * Print
 **********************************************************************************/

@media print {
  a {
    color: #000 !important;
    text-decoration: none;
  }

  p {
    orphans: 3;
    widows: 3;
  }
}
